
import { defineComponent, onMounted } from "vue";
import WebCam from "@/components/client/KYC/camera.vue";
import useMedia from "@/core/composables/useMedia";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { ref } from "vue";
import useNotify from "@/core/composables/notify";
import Spinner from "@/components/Spinner.vue";
import { computed } from "vue";
import Button from "@/components/buttons/Button.vue";

enum FileType {
  FRONT = "official_doc_file",
  BACK = "official_doc_back_file",
  SELFIE = "live_photo",
}

interface Capture {
  capture: string;
  fileType?: FileType;
}

interface CaptureType {
  title: string;
  subtitle: string;
  fileType: FileType;
}

export default defineComponent({
  name: "captures",
  components: { WebCam, Spinner, Button },
  setup() {
    const route = useRoute();
    const { uploadFile, dataURLtoFile } = useMedia();
    const { toastErrorRaw, toastSuccess } = useNotify();

    const busy = ref(false);
    const hasCaptured = ref(false);
    const completedCaptures = ref<Capture[]>([]);

    const instructions = ref(["Ensure you are in a well-lit area"]);

    const capturesToTake = computed<CaptureType[]>(() => {
      let list: any = [];
      const { type, back } = route.query;
      switch (type) {
        case "verification":
          list = [
            {
              title: "FRONT DOCUMENT CAPTURE",
              subtitle: "Capture the front of your document",
              fileType: FileType.FRONT,
            },

            // {
            //   title: "SELFIE CAPTURE",
            //   subtitle: "Take a selfie",
            //   fileType: FileType.SELFIE,
            // },
          ];
          if (back) {
            list.push({
              title: "BACK DOCUMENT CAPTURE",
              subtitle: "Capture the back of your document",
              fileType: FileType.BACK,
            });
          }
          break;

        default:
          list = [
            {
              title: "DOCUMENT CAPTURE",
              subtitle: "Upload the front of your document",
              fileType: FileType.FRONT,
            },
          ];
          break;
      }

      return list;
    });

    const putOrReplaceCapture = (photo, fileType) => {
      const existingCaptureIdx = completedCaptures.value.findIndex(
        (c) => c.fileType === fileType
      );
      if (existingCaptureIdx >= 0) {
        completedCaptures.value[existingCaptureIdx] = {
          ...completedCaptures.value[existingCaptureIdx],
          capture: photo,
        };
      } else {
        completedCaptures.value.push({
          capture: photo,
          fileType: fileType,
        });
      }
    };

    const onCaptureComplete = async (
      data: { url: string; file: any },
      capture: CaptureType
    ) => {
      busy.value = true;
      putOrReplaceCapture(data.file, capture.fileType);
      const filename = `capture_${new Date().getTime()}`;
      await uploadFile({
        file: dataURLtoFile(data.file, filename),
        description: "Document Capture",
        alt: "Document Capture",
        file_name: filename,
        token: (route.params.token as string) || "",
        fileProps: {
          // scan: true,
          // scan: capturesToTake.value[idx].fileType !== FileType.SELFIE,
          is_kyc_file: true,
        },
        meta: JSON.stringify({
          fileType: capture.fileType || "",
        }),
      })
        .then((file) => {
          putOrReplaceCapture(file?.url ?? "", capture.fileType);

          // toastSuccess({
          //   text: "Capture completed successfully.",
          // });
          // hasCaptured.value = true;
          // const existingCaptureIdx = completedCaptures.value.findIndex(
          //   (c) => c.fileType === capture.fileType
          // );
          // if (existingCaptureIdx >= 0) {
          //   completedCaptures.value[existingCaptureIdx] = {
          //     ...completedCaptures.value[existingCaptureIdx],
          //     capture: file,
          //   };
          // } else {
          //   completedCaptures.value.push({
          //     capture: file,
          //     fileType: capture.fileType,
          //   });
          // }
        })
        .catch((error) => {
          console.error(error);
          toastErrorRaw(error);
        })
        .finally(() => {
          console.log("Finally");
          busy.value = false;
        });

      // update KYC or BGC
      // await ApiService.post("captures/", {
      //   file: upload.id,
      //   token: route.params.token,
      // } as any)
      //   .then(({ data }) => {
      //     toastSuccess({
      //       text: "Capture completed successfully. You may proceed with your onboarding",
      //     });
      //     hasCaptured.value = true;
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     toastErrorRaw(error);
      //   })
      //   .finally(() => {
      //     console.log("FInally");
      //     busy.value = false;
      //   });
    };

    onMounted(() => {
      setCurrentPageTitle("Captures");
      setCurrentPageBreadcrumbs("Capture", ["Capture"]);
    });

    return {
      onCaptureComplete,
      busy,
      hasCaptured,
      capturesToTake,
      instructions,
      completedCaptures,
      FileType,
    };
  },
});
